/**
 * @deprecated use createSingletonService
 */
export class SingletonServiceProvider {
  static Create<T>(factory: any): T {
    return createSingletonService(factory);
  }
}

/**
 * Preferred Usage
 * @param factory
 */
export default function createSingletonService<T>(factory: any, id = 'default'): T {
  if (!factory._Instance) factory._Instance = {};
  if (!factory._Instance[id]) {
    const instance = factory();
    if (instance.autoInit) instance.autoInit();
    factory._Instance[id] = instance;
  }

  return factory._Instance[id];
}

// usage : return createSingletonService<ReturnType<typeof factory>>(factory);
